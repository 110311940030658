
import { Routes,Route } from 'react-router-dom';
import React, {useState, useEffect,useRef} from 'react';
import { useNavigate} from 'react-router-dom';
import './App.css';
import axios from "axios";
import Main from './components/Main';
import Home from './components/Home';
import UserService from './services/UserService';
import ProfessionalCategories from './components/ProfessionalCategories';
import Access from './components/Access';
import { GlobalContext } from './contexts/GlobalContext';
import Services from './components/Services';
import Login from './components/Login';
import Register from './components/Register';
import Confirm from './components/Confirm';
import ChangePassword from  './components/ChangePassword';
import Payment from './components/Payment';
import FinishRegistration from './components/FinishRegistration';
import EventRegistration from './components/EventRegistration';
import Association from './components/Association';
import About from './components/About';
import News from './components/News';
import Organisation from './components/Organisation';
import Membership from './components/Membership';
import {organisation,membership,getSettings} from './data';
import Settings from './components/Settings';

//axios.defaults.baseURL = 'http://localhost:8080/api/';
axios.defaults.baseURL = 'https://coaz.org:8085/coaz/api/';

const convertToId = (value) => {
  return value.replace(/ /g,'_').toLowerCase();
}

const hasAuthority = (user,authority) => {
  if(!user) {
    return false;
  }
  for(let i = 0; i < user.authorities.length; i++) {
      if(user.authorities[i].authority === authority) {
        return true
      }
    }
  return false;
} 

const findMenu = (menu,link) => {
  if(menu.link == link) {
    return menu;
  } else if(menu.menus) {
    for(let subMenu of menu.menus) {
      let found = findMenu(subMenu,link);
      if(found) {
        return found;
      }
    }
  }
  return null;
}

const getTextWidth = (text,font,fontSize) => { 
  let span = document.createElement("span"); 
  document.body.appendChild(span); 
  span.style.fontFamily = font; 
  span.style.fontSize = fontSize + "px"; 
  span.style.height = 'auto'; 
  span.style.width = 'auto'; 
  span.style.position = 'absolute'; 
  span.style.whiteSpace = 'no-wrap'; 
  span.innerHTML = text; 

  let width = Math.ceil(span.clientWidth);   
  document.body.removeChild(span); 
  return width;
} 

function App() { 
  const [user,setUser] = useState(null);
  const [reloadUser,setReloadUser] = useState({reload:true});
  const [dialog,setDialog] = useState(null);
  const [mobileMode,setMobileMode] = useState(false);
  const [mobileModeMenuIndex,setMobileModeMenuIndex] = useState(0);
  const [mobileModeMenuIndexs,setMobileModeMenuIndexs] = useState([0]);
  const [showMobileModeMenu,setShowMobileModeMenu] = useState(false);
  const [loading,setLoading] = useState(false);

  const mainElementRef = useRef(null);

  const createRoute = (menu,Element) => {
      let route = null;
      if(menu.menus) {
        route = <Route path={menu.link} element={<Element/>}>
                    {menu.menus.map((submenu,i) =>
                       createRoute(submenu,Element)
                    )}
                </Route>
      } else {
        route = <Route path={menu.link} element={<Element/>}/>
      }
      return route;
  }

  useEffect(() => {
      if(reloadUser.reload) {
          setReloadUser({reload:false});
          ( async () => {
              await UserService.getCurrentUser(false)
              .then((response) => {
                  if(response.content) {
                      setUser(response.content);
                  }  else {
                      setUser(null);
                      UserService.logout();
                  }
              })
              .catch((error) => {
                  setUser(null);
                  UserService.logout();
              })
          }
          )(); 
      }     
      const observer = new ResizeObserver(entries => {
        for (let entry of entries) {
            let rect = entry.target.getBoundingClientRect();
            setMobileMode(rect.width < 1024);
        }
      });
      observer.observe(document.documentElement)
      return () => {
        observer.disconnect();
      };
  }, [reloadUser]);

  return (
        <div className='flex bg-white bg-cover bg-center w-full min-h-screen'>
            <GlobalContext.Provider value={{user,mainElementRef,mobileMode,reloadUser,setReloadUser,convertToId,hasAuthority,dialog,setDialog,
                mobileModeMenuIndex,setMobileModeMenuIndex,showMobileModeMenu,setShowMobileModeMenu,
                mobileModeMenuIndexs,setMobileModeMenuIndexs,findMenu,getTextWidth,loading,setLoading}}>
                <Routes>
                    <Route path='/' element={<Main/>}>
                      <Route index element={<Home/>}/>
                      <Route path='home' element={<Home/>}/>
                      <Route path='association/:duty?' element={<Association/>}/>
                      <Route path='services/:service?' element={<Services/>}/>
                      <Route path='categories/:category?' element={<ProfessionalCategories/>}/>
                      {createRoute(organisation,Organisation)}
                      {createRoute(membership,Membership)}
                      <Route path='about' element={<About/>}/>
                      <Route path='news' element={<News/>}/>
                      <Route path='settings/:setting?/:item?' element={<Settings/>}/>
                    </Route>
                    <Route path='/access' element={<Access/>}>
                      <Route path='login' element={<Login/>}/>
                      <Route path='register' element={<Register/>}/>
                      <Route path='confirm/:username' element={<Confirm/>}/>
                      <Route path='changepassword/:username' element={<ChangePassword/>}/>
                      <Route path='payment' element={<Payment/>}/>
                      <Route path='finishregistration/:id' element={<FinishRegistration/>}/>
                      <Route path='eventregistration/:receivableid/:userid?' element={<EventRegistration/>}/>
                    </Route>
                </Routes>
                {dialog && dialog.show && dialog.Component && 
                <div className='fixed flex items-center justify-center p-4 w-screen h-screen z-40 bg-[rgba(0,0,0,.5)]'>
                        <dialog.Component/>
                </div>
                }
                {loading && 
                <div className='fixed flex items-center justify-center p-4 w-screen h-screen z-50'>
                    
                </div>
                }
            </GlobalContext.Provider>
        </div>
  )
}

export default App;
