
import axios from "axios";
import https from 'https';
import RoleService from '../services/RoleService'
import ReceivableService from "./ReceivableService";
import PaymentService from "./PaymentService";


class UserService {
    async login(username,password) {
        sessionStorage.setItem("access_token",'');
        sessionStorage.setItem("refresh_token",'');
        let responseObject = {};
        await axios.get("login",{params:{username:username,password:password}})
        .then((response) => {
            if(response.data['access_token']) {
                sessionStorage.setItem("access_token",response.data['access_token']);
                sessionStorage.setItem("refresh_token",response.data['refresh_token']);
                responseObject = {message:'successfull'};
            }
        })
        .catch(async (error) => {
            if(error.response && error.response.status === 401) { 
                if (error.response.data['error_message'] && error.response.data['error_message'].toLowerCase().includes('bad credentials')) {
                    responseObject = {error_message:'Incorrect username or password'};
                }else {
                    responseObject = error.response.data;
                }
            } else {
                responseObject = {error_message:error.message};
            }
        })

        return responseObject;
    }

    logout() {
        sessionStorage.setItem("access_token",'');
        sessionStorage.setItem("refresh_token",'');
    }

    async saveUser(user,autoPassword,refresh){
        let responseObject = {};
        await axios.post("user", user,{params:{autopassword:autoPassword},headers:{Authorization:`bearer ${sessionStorage.getItem("access_token")}`}})
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.saveUser(user,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response && error.response.data && error.response.data.message) {
                        responseObject =  error.response.data.message;
                    } else {
                        responseObject =  error.message;
                    }
                }); 
            }
        })
        return responseObject;
    }

    async saveUserSelf(user){
        let responseObject = {};
        await axios.post("user/self", user)
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
        })
        return responseObject;
    }

    async saveUsers(users,password,defaultRole,refresh){
        let responseObject = {};
        await axios.post("users", users,{
            params:{
                password:password,
                defaultRole:defaultRole
            },
            headers:{
                Authorization:`bearer ${sessionStorage.getItem("access_token")}`
            }
        })
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.saveUsers(users,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response && error.response.data && error.response.data.message) {
                        responseObject =  error.response.data.message;
                    } else {
                        responseObject =  error.message;
                    }
                }); 
            }
        })
        return responseObject;
    }

    async createMoodleUser(userId,refresh) {
        let responseObject = {};
        await axios.post("user/moodle",null,{params:{userid:userId},headers:{Authorization:`bearer ${sessionStorage.getItem("access_token")}`}})
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data) {
                responseObject =  error.response.data;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.createMoodleUser(userId,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response && error.response.data && error.response.data.message) {
                        responseObject =  error.response.data.message;
                    } else {
                        responseObject =  error.message;
                    }
                }); 
            }
        })
        return responseObject;
    }

    async createLedgerAccount(user,refresh) {
        let responseObject = {};
        await axios.post("user/ledger",user,{headers:{Authorization:`bearer ${sessionStorage.getItem("access_token")}`}})
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.createLedgerAccount(user,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response && error.response.data && error.response.data.message) {
                        responseObject =  error.response.data.message;
                    } else {
                        responseObject =  error.message;
                    }
                }); 
            }
        })
        return responseObject;
    }

    async updateUser(user,refresh){
        let responseObject = {};
        user.authorities = [];
        await axios.put("user", user,{headers:{Authorization:`bearer ${sessionStorage.getItem("access_token")}`}})
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.updateUser(user,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response && error.response.data && error.response.data.message) {
                        responseObject =  error.response.data.message;
                    } else {
                        responseObject =  error.message;
                    }
                }); 
            } 
        })

        return responseObject;
    }

    async updateUserSelf(user,setPassword,oldPassword,newPassword,refresh){
        let responseObject = {};
        user.authorities = [];
        await axios.put("user/self",user,{
            params:{
                setpassword:setPassword,
                oldpassword:oldPassword,
                newpassword:newPassword
            },
            headers:{
                Authorization:`bearer ${sessionStorage.getItem("access_token")}`
            }
        })
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.updateUserSelf(user,setPassword,oldPassword,newPassword,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response && error.response.data && error.response.data.message) {
                        responseObject =  error.response.data.message;
                    } else {
                        responseObject =  error.message;
                    }
                }); 
            } 
        })

        return responseObject;
    }

    async getUsers (filter,page,refresh) {
        let responseObject = null;
        await axios.get("users",{
            params: {
                username:filter.username,
                firstname:filter.firstname,
                lastname:filter.lastname,
                middlename:filter.middlename,
                sex:filter.sex,
                dateOfBirthDay:filter.dateOfBirthDay,
                dateOfBirthMonth:filter.dateOfBirthMonth,
                dateOfBirthYear:filter.dateOfBirthYear,
                idType:filter.idType,
                idNumber:filter.idNumber,
                nationality:filter.nationality,
                email:filter.email,
                secondaryEmail:filter.secondaryEmail,
                phone1:filter.phone1,
                phone2:filter.phone2,
                physicalAddress:filter.physicalAddress,
                postalAddress:filter.postalAddress,
                province:filter.province,
                district:filter.district,
                program:filter.program,
                institution:filter.institution,
                professionalCategory:filter.professionalCategory,
                employed:filter.employed,
                employer:filter.employer,
                organizationalUnit:filter.organizationalUnit,
                currentPosition:filter.currentPosition,
                facility:filter.facility,
                confirmed:filter.confirmed,
                selfRegistration:filter.selfRegistration,
                createdOnDay:filter.createdOnDay,
                createdOnMonth:filter.createdOnMonth,
                createdOnYear:filter.createdOnYear,
                status:filter.status,
                pageNo:page.pageNo,
                pageSize:page.pageSize,
                sortBy:page.sortBy,
                sortDir:page.sortDir
            },
            headers:{
                Authorization:`bearer ${sessionStorage.getItem("access_token")}`
            }
        })
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.getUsers(filter,page,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response && error.response.data && error.response.data.message) {
                        responseObject =  error.response.data.message;
                    } else {
                        responseObject =  error.message;
                    }
                }); 
            } 
        })

        return responseObject;
    }

    async getUser(userId,refresh){
        let responseObject = {};
        await axios.get(`user/${userId}`,{headers:{Authorization:`bearer ${sessionStorage.getItem("access_token")}`}})
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.getUser(userId,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response && error.response.data && error.response.data.message) {
                        responseObject =  error.response.data.message;
                    } else {
                        responseObject =  error.message;
                    }
                }); 
            } 
        })

        return responseObject;
    }

    async getCurrentUser(refresh){
        let responseObject = {};
        await axios.get("user/current",{headers:{Authorization:`bearer ${sessionStorage.getItem("access_token")}`}})
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.getCurrentUser(true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response && error.response.data && error.response.data.message) {
                        responseObject =  error.response.data.message;
                    } else {
                        responseObject =  error.message;
                    }
                }); 
            } 
        })

        return responseObject;
    }

    async deleteUser (id,refresh) {
        let responseObject = {};
        await axios.delete(`user/${id}`,{headers:{Authorization:`bearer ${sessionStorage.getItem("access_token")}`}})
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data) {
                responseObject =  error.response.data;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                responseObject = {status:'401',message:error.message};
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.deleteUser (id,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                }); 
            } 
        })

        return responseObject;
    }

    async confirm (username, code) {
        let responseObject = {};
        await axios.put("user/confirm/self",null,{params:{username:username,code:code}})
        .then((response) => {
            responseObject = response.data;
        })
        .catch((error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
        })
        return responseObject;
    }

    async confirmUser (username, refresh) {
        let responseObject = {};
        await axios.put('user/confirm',null,
        {
            params:{
                username:username
            },
            headers:{Authorization:`bearer ${sessionStorage.getItem("access_token")}`}
        })
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            console.log(error);
            if(error.response && error.response.data) {
                responseObject =  error.response.data;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                responseObject = {status:'401',message:error.message};
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.confirmUser(username,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                }); 
            } 
        })

        return responseObject;
    }

    async sendConfirmationCode (username) {
        let responseObject = {};
        await axios.put("user/sendconfirmationcode",null,{params:{username:username}})
        .then((response) => {
            responseObject = response.data;
        })
        .catch((error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
        })
        return responseObject;
    }

    async setPassword (username, oldpassword,newpassword) {
        let responseObject = {};
        await axios.put("user/setpassword",null,{
            params:{
                username:username,
                oldpassword:oldpassword,
                newpassword:newpassword
            },
            headers:{
                Authorization:`bearer ${sessionStorage.getItem("access_token")}`}
        })
        .then((response) => {
            responseObject = response.data;
        })
        .catch((error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
        })
        return responseObject;
    }

    async finishRegistration (username,newUsername,email,oldPassword,newPassword,refresh) {
        let responseObject = {};
        await axios.put('user/finishregistration',null,
            {
                params:{
                    username:username,
                    newusername:newUsername,
                    email:email,
                    oldpassword:oldPassword,
                    newpassword:newPassword
                },
                headers:{
                    Authorization:`bearer ${sessionStorage.getItem("access_token")}`
                }
            })
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            console.log(error);
            if(error.response && error.response.data) {
                responseObject =  error.response.data;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                responseObject = {status:'401',message:error.message};
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.confirmUser(username,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                }); 
            } 
        })
        return responseObject;
    }

    async resetPassword (username,refresh) {
        let responseObject = {};
        await axios.put('user/resetpassword',null,
            {
                params:{
                    username:username
                },
                headers:{Authorization:`bearer ${sessionStorage.getItem("access_token")}`}
            })
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                responseObject = {status:'401',message:error.message};
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.resetPassword(username,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject = error.response.data.message;
                            } else {
                                responseObject = error.message;
                            }
                        })
                    } 
                })
                .catch((error) => {
                    console.log(error);
                }); 
            } 
        })

        return responseObject;
    }

    async forgotPassword (username) {
        let responseObject = {};
        await axios.put('user/forgotpassword',null,
        {
            params:{
                username:username
            }
        })
        .then((response) => {
            responseObject = response.data;
        })
        .catch((error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
        })
        return responseObject;
    }

    createUser (user) {
        var roles = [];
        for(let i = 0; i < user.roles.length; i++)
        {
            roles.push(RoleService.createRole(user.roles[i]).object);
        }
        return {
            object: {
                id:user.id,
                username:user.username,
                password:user.password,
                firstname:user.firstname,
                lastname:user.lastname,
                middlename:user.middlename,
                sex:user.sex,
                dateOfBirth:user.dateOfBirth,
                idType:user.idType,
                idNumber:user.idNumber,
                nationality:user.nationality,
                email:user.email,
                secondaryEmail:user.secondaryEmail,
                phone1:user.phone1,
                phone2:user.phone2,
                physicalAddress:user.physicalAddress,
                postalAddress:user.postalAddress,
                province:user.province,
                district:user.district,
                program:user.program,
                institution:user.institution,
                professionalCategory:user.professionalCategory,
                employed:user.employed,
                employer:user.employer,
                organizationalUnit:user.organizationalUnit,
                currentPosition:user.currentPosition,
                facility:user.facility,
                image:user.image,
                ledgerAccountRef:user.ledgerAccountRef,
                moodleId:user.moodleId,
                confirmed:user.confirmed,
                otp:user.otp,
                accountNonLocked:user.accountNonLocked,
                enabled:user.enabled,
                selfRegistration:user.selfRegistration,
                roles:roles 
            },
            columns:[
                {value:user.firstname,isBoolean:false},
                {value:user.lastname,isBoolean:false},
                {value:user.username,isBoolean:false},
                {value:user.primaryEmail,isBoolean:false}
            ],
            selected:false
        };
    }

    async checkUser (user) {
        let url = {path:'error'};
        if(user && user.status) {
            switch (user.status.toLowerCase()) {
                case 'otp' :{
                    url = {path:`/access/changepassword/${user.username}`};
                    break;
                }
                case 'incomplete_registration' : {
                    url = {path:`/access/finishregistration/${user.id}`};
                    break;
                }
                default : {
                    if(user.role) {
                        let paid = false;
                        await ReceivableService.hasUserPaid(user.role.id,user.id)
                        .then((paidResponse) => {
                            if(paidResponse.status && paidResponse.status === 'YES') {
                                paid = true;
                            }
                        })
                        .catch((error) => {
                            console.log(error.message);
                        });
                        if(!paid) {
                            const map = new Map();
                            map.set('receivableId',user.role.id);
                            await PaymentService.getPaymentTariff(map,false)
                            .then((tariffResponse) => {
                                if(tariffResponse.status && tariffResponse.status === 'SUCCESSFUL' && tariffResponse.content) {
                                    if(tariffResponse.content) {
                                        const params = new Map();
                                        params.set('receivableId',user.role.id);
                                        url = {
                                            path:'/access/payment',
                                            params:params
                                        };
                                    } 
                                } else if(tariffResponse.status && tariffResponse.status === 'SUCCESSFUL') {
                                    url = {
                                        path:''
                                    };
                                } else {
                                    console.log(tariffResponse)
                                }
                            })
                            .catch((error) => {
                                console.log(error.message);
                            });
                        } else {
                            url = {path:''};
                        }
                    }
                }
            }
        }

        return url;
    }
}

export default new UserService();